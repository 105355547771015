export const newsList = [
  {
    id: 330,
    src: require('../../assets/images/mainPageImages/newsImages/news-330.jpg'),
    title: 'Премия "Человек года"',
    description: 'В субботу, 14 декабря, состоялась церемония «Человек года —2024» Краснопахорского района. - Эту премию мы вручали впервые в 2023 году. В этом году после объединения территорий в число номинантов вошли представители со всего района, усилиями и талантом которых мы по праву можем гордиться, - подчеркнул глава управы Юрий Няньчур.Главная премия «Человек года 2024» была вручена Герою РФ Бахареву Максиму Владимировичу. Максим - чемпион мира по карате, член Союза писателей России, участник специальной военной операции. В 2024 году Указом Президента Российской Федерации за мужество и отвагу капитану Максиму Владимировичу Бахареву присвоено звание Героя Российской Федерации с вручением медали «Золотая Звезда». «Я горжусь, что в нашем районе есть такие люди как вы – достойный пример целеустремленности, трудолюбия, ответственности, верности долгу и Родине.  Я уверена, что вашими усилиями, талантом и старанием будет и дальше процветать Краснопахорская земля», - поделилась глава Краснопахорского района Матвеева Надежда.'
  },
  {
    id: 329,
    src: require('../../assets/images/mainPageImages/newsImages/news-329.jpg'),
    title: 'Концерт ко Дню Героев Отечества',
    description: '6 декабря коллективы Краснопахорского дома культуры поздравили местных жителей с Днём Героев Отечества. К памятной дате 83-ей годовщины обороны Москвы и контрнаступления советской армии против фашистских захватчиков в Великой Отечественной войне 1941-1945 гг. солисты студий представили творческие постановки на ⚔️⚔️⚔️военно-патриотическую тему. В концертном зале были особые гости - военнослужащие из войсковых частей района. В программе прозвучали песни и стихотворения о любви к Родине. Хореографические постановки и акробатические номера были исполнены под музыку военных лет и очень  понравились зрителям.    '
  },
  {
    id: 328,
    src: require('../../assets/images/mainPageImages/newsImages/news-328.jpg'),
    title: 'Письмо',
    description: '"Я так люблю, когда приходят письма, Я вам пришлю свои мечты и мысли. На чистый лист легко ложатся строчки, А в строчках жизнь. Они спешат к вам почтой",- это слова известной песни, одного из хитов российской эстрады прошлых лет. Ставьте, если знаете эту песню! Ставьте, если любите эту композицию и её исполнительницу! Писать, принимать и отправлять письма для Дедушки Мороза продолжают в доме культуры "Звёздный". Сегодня свой заветный конверт в почтовый ящик опустила  Диана Вещева. Главное условие участия в акции "Письмо Деду Морозу" - ОСТАВЛЯТЬ ТЕЛЕФОН РОДИТЕЛЕЙ для обратной связи'
  },
  {
    id: 327,
    src: require('../../assets/images/mainPageImages/newsImages/news-327.jpg'),
    title: 'Рождественнские ангелы',
    description: 'Мы все любим получать подарки. Как приятно держать в руках то, что передано от души, с любовью и от всего сердца. В преддверии новогодних и рождественских праздников в кружке "Бисероплетение" ДК "Звёздный" ребята вместе с руководителем  Ириной Сизовой сделали маленькие сувениры. Эти рождественские ангелы из ярких разноцветных бусин  - подарки участникам СВО. В самое ближайшее время миниатюры из бисера будут переданы  российским военным, которые встретят Новый 2025 год и Рождество Христово далеко от дома, выполняя свой гражданский долг и защищая Отечество!'
  },
  {
    id: 326,
    src: require('../../assets/images/mainPageImages/newsImages/news-326.jpg'),
    title: 'Звезда по имени мама',
    description: 'В красивую дату, 24.11.24, на сцене Краснопахорского дома культуры в честь Дня матери сияли претендентки на звание главной "Звезды по имени МАМА". Молодые и жизнерадостные, креативные и творческие девушки представили дефиле, визитку, ответили на вопросы интеллектуальной викторины и покорили сердца жюри своими талантами в заключительном этапе ежегодного конкурса.По итогу всех испытаний Ксения Ларионова лучшая в номинации "Талант и одаренность". Екатерина Шупикова удостоилась звания "Стильная и женственная". Диана Уберти по праву признана обладательницей диплома "Добрая, мудрая и понимающая". Жанна Гаспарян взяла букет наград. Именно Жанна - "Креативная и позитивная", лидер голосования зрителей и "ЗВЕЗДА ПО ИМЕНИ МАМА" 2024. Мы поздравляем с Днём матери участниц и судей, организаторов и гостей нашего праздника! Будьте любимы, здоровы и счастливы!'
  },
  {
    id: 325,
    src: require('../../assets/images/mainPageImages/newsImages/news-325.jpg'),
    title: 'Кто много читает',
    description: 'Кто много читает, не только много знает, но и получает сладкие призы за правильные ответы в викторинах. Одна из познавательных и развлекательных встреч для детей прошла в библиотеке посёлка Минзаг 16 ноября. Заведующая Краснопахорской библиотечной системы  Людмила Абрамова сопроводила юных читателей в книжном путешествии по "СТРАНЕ ДЕДА МОРОЗА", где ребята разгадывали загадки, отвечали на вопросы викторины и справились со всеми заданиями литературного кроссворда. Предновогодняя выставочная экспозиция ярких иллюстрированных изданий работает до конца месяца в читальном зале Минзага.'
  },
  {
    id: 324,
    src: require('../../assets/images/mainPageImages/newsImages/news-324.jpg'),
    title: 'Мамы нежная улыбка',
    description: 'Последнее воскресенье ноября в этом году приходится на 24 число. Именно тогда мы отметим ДЕНЬ МАТЕРИ. В преддверии этого праздника в доме культуры "Юбилейный" открыли фотовыставку: "Мамы нежная улыбка", которая продлится до конца ноября. В экспозиции представлены работы  специалиста по связям с общественностью Краснопахорского дома культуры Инны Чистовой: это репортажные и постановочные снимки, сделанные во время мероприятий ДК  "Звёздный" и ДК  "Юбилейный", а также фотографии из семейных архивов ребят, которые занимаются в наших кружках и студиях. Дорогие и любимые наши МАМЫ, мы поздравляем вас с праздником, желаем крепкого здоровья, терпения и ЖЕНСКОГО СЧАСТЬЯ! А главное помните: неважно, сколько лет нашим детям, их радости нет предела, когда они видят МАМЫ НЕЖНУЮ УЛЫБКУ'
  },
  {
    id: 323,
    src: require('../../assets/images/mainPageImages/newsImages/news-323.jpg'),
    title: 'День рождения главного волшебника',
    description: 'Мастер-класс, игры и знакомство с Дедом Морозом 18 ноября в честь Дня рождения главного зимнего волшебника для детей Краснопахорского района и их родителей в доме культуры "Звёздный" прошла творческая развлекательная встреча. Елена Михайлина помогла ребятам изготовить новогодние открытки с цветными аппликациями. Вместе с Мадиной Романчевой и Дедом Морозом дети и подростки выполняли задания весёлых конкурсов, танцевали и водили хоровод!'
  },
]

export const moscowEvents = [
    {
      id: 2,
      type: 'Интерактивная, Классическое искусство',
      name: 'Айвазовский. Кандинский. Живые полотна',
      place: 'Пространство «Люмьер-холл»',
      date: 'до 23 апреля 2023',
      description: 'На выставке представлено более 100 работ Айвазовского из музеев по всей России, большинство из них сделано в формате ps-3D, что позволяет посетителям почувствовать себя внутри картины. А мощные проекторы высокого разрешения дополненные объемным звуком перенесут посетителей за тысячи километров в акваторию Черного моря на место известных корабельных сражений.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-2.jpg'),
      to: 'https://www.afisha.ru/exhibition/258674/'
    },
    {
      id: 3,
      type: 'Выставка в Москве',
      name: 'Первые моторы России',
      place: 'Музей гаража особого назначения ФСО России',
      date: 'до 27 октября 2023',
      description: 'О первых страницах истории русского автомобилизма — зарождении технической культуры, творчества, инженерной школы — расскажет выставка «Первые моторы России» в Музее Гаража особого назначения на ВДНХ.Павильон 54 музея превращен в зал IV Международной автомобильной выставки. В исторических интерьерах представлены уникальные экспонаты из коллекции Политехнического музея, находившиеся в запасниках и впервые собранные вместе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-3.jpeg'),
      to: 'https://www.afisha.ru/exhibition/272590/'
    },
    {
      id: 4,
      type: 'ВЫСТАВКА В МОСКВЕ',
      name: 'Космонавтика и авиация',
      place: 'Павильон «Космос»',
      date: 'Постоянная экспозиция',
      description: 'В основной экспозиции — огромный архив документальных свидетельств покорения космоса: 120 образцов одной только техники и больше 2 тысяч разных документов, включая фото и видео. Под куполом расположен 5D-кинотеатр, который рассказывает о Большом взрыве и светлом будущем с колонией на Марсе.',
      src: require('../../assets/images/mainPageImages/newsImages/moscow-news-4.jpg'),
      to: 'https://www.afisha.ru/exhibition/208082/'
    }
]