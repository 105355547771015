export const eventsConfig = [
    {
        id: 27,
        eventDay: '26',
        eventDayNumber: 'ВС',
        eventInfoDate: '26 января',
        eventInfoTitle: 'Конкурсно-игровая программа',
        eventDescription: '26 января В 15-00 в Доме культуры «Звездный»,состоится конкурсно-игровая программа "Татьянин день" ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/Татьянин день-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Татьянин день-02.jpg'),
    },
    {
        id: 26,
        eventDay: '25',
        eventDayNumber: 'СБ',
        eventInfoDate: '25 января',
        eventInfoTitle: 'Мастер-класс',
        eventDescription: '25 января В 12-00 в клубном формировании "Клубочек" состоится мастер-класс "Подарок для Татьяны" ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/Подарок для бубушки-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Подарок для бубушки-02.jpg'),
    },
    {
        id: 25,
        eventDay: '17',
        eventDayNumber: 'ПТ',
        eventInfoDate: '17-31 января',
        eventInfoTitle: 'Расписание мероприятий ДК «Звёздный»',
        eventDescription: 'С 17-31 января мероприятия в Доме культуры «Звездный», всех ждем! ВХОД СВОБОДНЫЙ',
        eventCost: 'Село Былово, 8а',
        eventImgSrc: require('../../assets/images/eventsImages/Январь25-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Январь25-02.jpg'),
    },
    {
        id: 24,
        eventDay: '14',
        eventDayNumber: 'ВТ',
        eventInfoDate: '14 января',
        eventInfoTitle: 'Ежегодный конкурс ёлочной игрушки',
        eventDescription: '14 января 2025 года в 18-00ч. года мы подведём итоги и наградим победителей! Всех участников ждем!',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/grushkaффф-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/grushkaффф-02.jpg'),
    },
    {
        id: 23,
        eventDay: '10',
        eventDayNumber: 'СБ',
        eventInfoDate: '10 января',
        eventInfoTitle: 'Встреча в клубе "Лира"',
        eventDescription: '10 января в 17-00ч. состоится встреча в клубе "Лира" 230 лет со дня рождения А. с. Грибоедова',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/Лира январь-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Лира январь-02.jpg'),
    },
    {
        id: 22,
        eventDay: '11',
        eventDayNumber: 'СБ',
        eventInfoDate: '11 января',
        eventInfoTitle: 'Зимние песни о главном',
        eventDescription: '11 января в 16-00ч. состоится музыкальныц вечер музыкального коллектива "Созвездние" ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/soz-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/soz-02.jpg'),
    },
    {
        id: 21,
        eventDay: '8',
        eventDayNumber: 'СР',
        eventInfoDate: '8 января',
        eventInfoTitle: 'ЦИРК',
        eventDescription: '08 января в 12-00ч. "ЗВЁЗДЫ МАНЕЖА" зажигают огни в доме культуры "Звёздный" В продаже сладкая вата и попкорн',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/цирк-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/цирк-02.jpg'),
    },
    {
        id: 11,
        eventDay: '03',
        eventDayNumber: 'ПТ',
        eventInfoDate: '03-08 января',
        eventInfoTitle: 'Расписание мероприятий ДК «Звёздный»',
        eventDescription: 'С 03-08 января мероприятия в Домах культуры «Звездный» и «Юбилейный» ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/Общ НГЗ-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Общ НГЗ-02.jpg'),
    },
]